import { useSelector } from "react-redux";
import { getPurchaseOrders } from "../../app/reducers/PurchaseOrders/purchaseOrderSlice";
import { object, string } from "yup";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptionsForPurchaseOrder } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import moment from "moment";
import { Archive } from "react-feather";

const validationSchema = object({
  purchaseOrder: string().required("PO Required"),
});

const computePurchaseOrders = (pos) => {
  const filteredPos = pos.filter((po) => {
    const startDate = moment(po.date).add(30, "days");
    const endDate = moment(po.date).add(75, "days");
    console.table({
      poNumber: po.purchaseorder_number,
      currentDate: moment(new Date()).format("DD-MM-YYYY"),
      start: startDate.format("DD-MM-YYYY"),
      end: endDate.format("DD-MM-YYYY"),
    });
    const date = moment(new Date());
    const today = moment();
    return (
      // date.isBetween(startDate, endDate) &&
      po?.cf_tag !== "PAYMENT COMPLAINT" &&
      po?.cf_tag_2 !== "COMPLAINT SOLVE" &&
      po?.status !== "cancelled" &&
      today.isAfter(
        moment(po.date).add(parseInt(po.cf_copy_payment_terms), "days")
      ) &&
      today.isBetween(startDate, endDate)
    );
  });
  return filteredPos;
};

const STEP = 1;
const action = "Select Purchase Order";

const Step = ({ formik, currentStep, setCurrentStep }) => {
  const { purchaseOrders } = useSelector(getPurchaseOrders);
  return (
    <>
      {currentStep + 1 === STEP && (
        <>
          <FormikSelectGroup
            name="purchaseOrder"
            label={
              <label className="flex gap-2 items-center">
                <img src="/formlabel.png" className="w-7" />{" "}
                <span className="text-xl text black my-2 ">
                  Enter Purchase Order
                </span>
              </label>
            }
            formik={formik}
            options={generateOptionsForPurchaseOrder({
              array: computePurchaseOrders(purchaseOrders),
              valueField: "purchaseorder_id",
              labelField: "purchaseorder_number",
              labelField2: "cf_buyer_name",
            })}
            required
          />
          <div className="mx-auto">
            <PrimaryButton
              type="button"
              onClick={async () => {
                const validation = await formik.validateForm();
                console.log(validation);
                if (_.isEmpty(validation)) {
                  setCurrentStep(currentStep + 1);
                } else {
                  console.log(validation);
                  alert("Please fill all the required fields");
                }
              }}
            >
              Next
            </PrimaryButton>
          </div>
        </>
      )}
    </>
  );
};

export default {
  Component: Step,
  validationSchema: validationSchema,
  action,
};
